import {
  type WidgetConfig,
  MS_PER_SECOND,
  SECS_PER_MINUTE,
  MINS_PER_HOUR,
  HOURS_PER_DAY,
  AUTHENTIC_BRAND_NAME,
  DEFAULT_INSURANCE_PORTAL_URL,
  TENANT_CUSTOMER_ID_URL_PARAM_NAME,
  UTM_SOURCE_URL_PARAM_NAME,
  UTM_MEDIUM_URL_PARAM_NAME,
  setUrlParams,
  browserStorage,
  getTheme,
  getEncodedInsurancePortalUrl,
  getTenantNameFromBrandName
} from "@authenticins/core";

import {
  WIDGET_ELEMENT_CLASS_NAME,
  injectGlobalStyles,
  injectIframe,
  injectModalHtml,
  injectPersistentBannerHtml
} from "./utils";

const PERSISTENT_BANNER_DISMISSAL_TIMEOUT_MS = MS_PER_SECOND * SECS_PER_MINUTE * MINS_PER_HOUR * HOURS_PER_DAY * 2.5;
export const PERSISTENT_BANNER_DISMISSAL_TIMESTAMP_LOCAL_STORAGE_KEY =
  "authentic_widget_persistent_banner_dismissal_timestamp";

/**
 * Creates and injects widget elements into the DOM.
 */
async function init(config: WidgetConfig = {}): Promise<void> {
  if (document.readyState === "loading") {
    window.addEventListener("DOMContentLoaded", () => {
      void init(config);
    });
    return;
  }
  // Remove any existing widget elements from the DOM.
  document.querySelectorAll("." + WIDGET_ELEMENT_CLASS_NAME).forEach((widgetElement) => {
    widgetElement.remove();
  });
  const tenantName = getTenantNameFromBrandName(config.themeOverrides?.brand?.name ?? AUTHENTIC_BRAND_NAME);
  const baseInsurancePortalUrl: string =
    // @ts-expect-error - Accomodate legacy widget config format.
    config.applicationBaseUrl ?? config.insurancePortal?.url ?? DEFAULT_INSURANCE_PORTAL_URL;
  let insurancePortalUrl = getEncodedInsurancePortalUrl(baseInsurancePortalUrl, config.prefillData, config.themeOverrides);
  if (insurancePortalUrl === null) {
    console.error("Authentic Widget error: Invalid Insurance Portal URL.", baseInsurancePortalUrl);
    return;
  }
  insurancePortalUrl = setUrlParams(
    {
      ...(typeof config.customerId !== "undefined" ? { [TENANT_CUSTOMER_ID_URL_PARAM_NAME]: config.customerId } : {}),
      [UTM_SOURCE_URL_PARAM_NAME]: tenantName,
      [UTM_MEDIUM_URL_PARAM_NAME]: `widget-${typeof config.insurancePortal?.targetSelector !== "undefined" ? "embed" : "modal"}`
    },
    insurancePortalUrl
  );
  if (insurancePortalUrl === null) {
    console.error("Authentic Widget error: Unable to set URL params.", baseInsurancePortalUrl);
    return;
  }
  // If an Insurance Portal target selector is specified, inject an iframe into it.
  if (typeof config.insurancePortal?.targetSelector !== "undefined") {
    injectIframe(insurancePortalUrl, config.insurancePortal.targetSelector);
    // Otherwise, inject the modal and persistent banner (if it's not currently dismissed).
  } else {
    const theme = getTheme(config.themeOverrides);
    injectGlobalStyles(theme);
    injectModalHtml(theme);
    const persistentBannerDismissalTimestamp = parseInt(
      browserStorage("local").getItem(PERSISTENT_BANNER_DISMISSAL_TIMESTAMP_LOCAL_STORAGE_KEY) ?? "0"
    );
    const shouldInjectPersistentBanner =
      persistentBannerDismissalTimestamp < Date.now() - PERSISTENT_BANNER_DISMISSAL_TIMEOUT_MS;
    if (!shouldInjectPersistentBanner) return;
    injectPersistentBannerHtml(theme, insurancePortalUrl);
  }
}

// @ts-expect-error - Expose widget controls to the browser window.
window.authenticWidget = { init };
