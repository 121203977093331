import { browserStorage } from "@authenticins/core";

import { PERSISTENT_BANNER_DISMISSAL_TIMESTAMP_LOCAL_STORAGE_KEY } from "..";
import { WIDGET_ELEMENT_IS_OPEN_CLASS_NAME, WIDGET_MODAL_WRAPPER_ID, WIDGET_PERSISTENT_BANNER_ID } from "./inject";

/**
 * Opens the widget's modal.
 * @returns `true` if modal not rendered.
 */
export function openModal(): boolean {
  const modalWrapper = document.getElementById(WIDGET_MODAL_WRAPPER_ID);
  if (modalWrapper === null) {
    console.error("Authentic Widget error: Modal not rendered (opening modal).");
    return true;
  }
  modalWrapper.classList.add(WIDGET_ELEMENT_IS_OPEN_CLASS_NAME);
  return false;
}

/**
 * Closes the widget's modal.
 * @returns `true` if modal not rendered.
 */
export function closeModal(): boolean {
  const modalWrapper = document.getElementById(WIDGET_MODAL_WRAPPER_ID);
  if (modalWrapper === null) {
    console.error("Authentic Widget error: Modal not rendered (closing modal).");
    return true;
  }
  modalWrapper.classList.remove(WIDGET_ELEMENT_IS_OPEN_CLASS_NAME);
  return false;
}

/**
 * Closes the widget's persistent banner.
 * @returns `true` if persistent banner not rendered.
 */
export function closePersistentBanner(): boolean {
  const persistentBanner = document.getElementById(WIDGET_PERSISTENT_BANNER_ID);
  if (persistentBanner === null) {
    console.error("Authentic Widget error: Persistent banner not rendered (closing persistent banner).");
    return true;
  }
  persistentBanner.classList.remove(WIDGET_ELEMENT_IS_OPEN_CLASS_NAME);
  return false;
}

/**
 * Closes the widget's persistent banner and stores a dismissal timestamp in local storage.
 * @returns `true` if unable to close persistent banner.
 */
export function dismissPersistentBanner(): boolean {
  const wasError = closePersistentBanner();
  if (wasError) {
    console.error("Authentic Widget error: Unable to close persistent banner (dismissing persistent banner).");
    return true;
  }
  return browserStorage("local").setItem(PERSISTENT_BANNER_DISMISSAL_TIMESTAMP_LOCAL_STORAGE_KEY, Date.now().toString());
}
