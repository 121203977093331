import {
  type Theme,
  MS_PER_SECOND,
  AUTHENTIC_BRAND_NAME,
  AUTHENTIC_WEBSITE_LINK,
  colorIsDark,
  elementWasClicked,
  getThemeStyles
} from "@authenticins/core";

import { openModal, closeModal, closePersistentBanner, dismissPersistentBanner } from "./controls";

export const WIDGET_ELEMENT_CLASS_NAME = "authentic-widget-element";
export const WIDGET_BUTTON_CLASS_NAME = "authentic-widget-button";
export const WIDGET_ELEMENT_IS_OPEN_CLASS_NAME = "is-open";
const WIDGET_MODAL_ID = "authentic-widget-modal";
export const WIDGET_MODAL_WRAPPER_ID = `${WIDGET_MODAL_ID}-wrapper`;
export const WIDGET_PERSISTENT_BANNER_ID = "authentic-widget-persistent-banner";

const MODAL_TOOLBAR_HEIGHT_PX = 48;
const PERSISTENT_BANNER_HEIGHT_PX = 125;
const ICON_BUTTON_SIZE_PX = 28;

/**
 * Injects global CSS for the widget into the DOM using the given {@linkcode Theme}.
 */
export function injectGlobalStyles(theme: Theme): void {
  const globalStyles = document.createElement("style");
  globalStyles.innerHTML = `
    .${WIDGET_ELEMENT_CLASS_NAME},
    .${WIDGET_ELEMENT_CLASS_NAME} * {
      box-sizing: border-box !important;
    }
    .${WIDGET_ELEMENT_CLASS_NAME} h1,
    .${WIDGET_ELEMENT_CLASS_NAME} h2,
    .${WIDGET_ELEMENT_CLASS_NAME} p {
      margin: unset !important;
    }
    .${WIDGET_BUTTON_CLASS_NAME} {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding: unset !important;
      background-color: unset !important;
      background: unset !important;
      font-size: ${theme.font.sizePx.base}px !important;
      border: none !important;
      border-radius: ${theme.border.radiusPx.base}px !important;
      box-shadow: none !important;
      cursor: pointer !important;
    }
  `;
  document.head.appendChild(globalStyles);
}

/**
 * Injects an iframe of the given Insurance Portal URL into the target element with the given selector.
 * @returns `true` if unable to find target element.
 */
export function injectIframe(insurancePortalUrl: string, targetSelector: string): boolean {
  const target = document.querySelector(targetSelector);
  if (target === null) {
    console.error("Authentic Widget error: Unable to find target element from selector (injecting iframe).", targetSelector);
    return true;
  }
  const iframe = document.createElement("iframe");
  iframe.className = WIDGET_ELEMENT_CLASS_NAME;
  iframe.src = insurancePortalUrl;
  // Needed to allow sharing of estimates and quotes on mobile.
  iframe.allow = "web-share; clipboard-write";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.border = "none";
  target.appendChild(iframe);
  return false;
}

/**
 * Injects the HTML for the widget's modal into the DOM using the given {@linkcode Theme}.
 * @returns `true` if unable to inject.
 */
export function injectModalHtml(theme: Theme): boolean {
  const modalWrapper = document.createElement("div");
  modalWrapper.className = WIDGET_ELEMENT_CLASS_NAME;
  modalWrapper.id = WIDGET_MODAL_WRAPPER_ID;
  modalWrapper.innerHTML = `
    <style>
      #${WIDGET_MODAL_WRAPPER_ID} #${WIDGET_MODAL_ID}-bg {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: ${getThemeStyles(theme, { darkenBg: true }).backgroundColor};
        opacity: ${theme.opacity.hidden};
        transition: all ${theme.transition.baseDurationMs}ms ${theme.transition.timingFunction};
        pointer-events: none;
        z-index: ${theme.element.zIndex.widget - 1};
      }
      #${WIDGET_MODAL_WRAPPER_ID} #${WIDGET_MODAL_ID} {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: ${theme.element.maxSizeViewportUnits}vw;
        height: ${theme.element.maxSizeViewportUnits}svh;
        border-radius: ${theme.border.radiusPx.lg}px;
        opacity: ${theme.opacity.hidden};
        transform: translate(-50%, -50%);
        transition: all ${theme.transition.baseDurationMs}ms ${theme.transition.timingFunction};
        overflow: hidden;
        pointer-events: none;
        z-index: ${theme.element.zIndex.widget};
      }
      #${WIDGET_MODAL_ID}-toolbar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${MODAL_TOOLBAR_HEIGHT_PX}px;
        padding: 0 ${theme.baseSpacingPx * 2}px;
        background-color: ${theme.palette.subtle};
        z-index: ${theme.element.zIndex.widget + 1};
      }
      #${WIDGET_MODAL_ID}-toolbar button {
        width: ${ICON_BUTTON_SIZE_PX}px !important;
        height: ${ICON_BUTTON_SIZE_PX}px !important;
      }
      #${WIDGET_MODAL_WRAPPER_ID} iframe {
        min-width: ${theme.element.maxSizeViewportUnits}vw;
        min-height: calc(${theme.element.maxSizeViewportUnits}svh - ${MODAL_TOOLBAR_HEIGHT_PX}px);
        padding-top: ${MODAL_TOOLBAR_HEIGHT_PX}px;
      }
      #${WIDGET_MODAL_WRAPPER_ID}.${WIDGET_ELEMENT_IS_OPEN_CLASS_NAME} #${WIDGET_MODAL_ID}-bg,
      #${WIDGET_MODAL_WRAPPER_ID}.${WIDGET_ELEMENT_IS_OPEN_CLASS_NAME} #${WIDGET_MODAL_ID}{
        opacity: 1;
        pointer-events: unset;
      }
    </style>
    <div id='${WIDGET_MODAL_ID}-bg'></div>
    <div id='${WIDGET_MODAL_ID}'>
      <div id='${WIDGET_MODAL_ID}-toolbar'>
        <button id='${WIDGET_MODAL_ID}-close-button' class='${WIDGET_BUTTON_CLASS_NAME}'>
          ${getCloseIconHtml(theme)}
        </button>
      </div>
    </div>
  `;
  document.body.appendChild(modalWrapper);
  const modalBackground = modalWrapper.querySelector<HTMLElement>(`#${WIDGET_MODAL_ID}-bg`);
  if (modalBackground === null) {
    console.error("Authentic Widget error: Modal background not rendered (injecting modal).");
    return true;
  }
  const modal = modalWrapper.querySelector<HTMLElement>("#" + WIDGET_MODAL_ID);
  if (modal === null) {
    console.error("Authentic Widget error: Modal not rendered (injecting modal).");
    return true;
  }
  modalBackground.addEventListener("click", (e) => {
    if (!elementWasClicked(modal, e)) closeModal();
  });
  const modalCloseButton = modalWrapper.querySelector(`#${WIDGET_MODAL_ID}-close-button`);
  if (modalCloseButton === null) {
    console.error("Authentic Widget error: Modal close button not rendered (injecting modal).");
    return true;
  }
  modalCloseButton.addEventListener("click", closeModal);
  return false;
}

/**
 * Injects the HTML for the widget's persistent banner into the DOM using the given {@linkcode Theme} and Insurance Portal URL.
 * @returns `true` if unable to inject.
 */
export function injectPersistentBannerHtml(theme: Theme, insurancePortalUrl: string): boolean {
  const persistentBanner = document.createElement("div");
  persistentBanner.className = WIDGET_ELEMENT_CLASS_NAME;
  persistentBanner.id = WIDGET_PERSISTENT_BANNER_ID;
  persistentBanner.innerHTML = `
    <style>
      #${WIDGET_PERSISTENT_BANNER_ID},
      #${WIDGET_PERSISTENT_BANNER_ID} div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      #${WIDGET_PERSISTENT_BANNER_ID} {
        flex-direction: column;
        position: fixed;
        bottom: -${PERSISTENT_BANNER_HEIGHT_PX}px;
        left: 50%;
        width: ${theme.element.maxSizeViewportUnits}vw;
        max-width: ${theme.view.maxContentWidthPx}px;
        height: ${PERSISTENT_BANNER_HEIGHT_PX}px;
        padding: ${theme.baseSpacingPx * 3}px;
        background-color: ${theme.palette.primary};
        color: ${theme.palette.primaryContrast};
        border-top-left-radius: ${theme.border.radiusPx.lg}px;
        border-top-right-radius: ${theme.border.radiusPx.lg}px;
        transform: translateX(-50%);
        transition: all ${theme.transition.baseDurationMs}ms ${theme.transition.timingFunction};
        z-index: ${theme.element.zIndex.widget};
      }
      #${WIDGET_PERSISTENT_BANNER_ID} h1 {
        padding-right: ${theme.baseSpacingPx * 4}px;
        color: ${theme.palette.primaryContrast} !important;
        font-size: ${theme.font.sizePx.xl}px !important;
        font-weight: bold !important;
      }
      #${WIDGET_PERSISTENT_BANNER_ID}-dismiss-button {
        position: absolute;
        top: ${theme.baseSpacingPx}px;
        right: ${theme.baseSpacingPx}px;
        width: ${ICON_BUTTON_SIZE_PX}px !important;
        height: ${ICON_BUTTON_SIZE_PX}px !important;
      }
      #${WIDGET_PERSISTENT_BANNER_ID}-cta-button {
        padding: ${theme.baseSpacingPx * 1.25}px ${theme.baseSpacingPx * 2}px !important;
        background-color: ${theme.palette.primaryContrast} !important;
        background: ${theme.palette.primaryContrast} !important;
        color: ${theme.palette.primary} !important;
        text-wrap: nowrap !important;
      }
      #${WIDGET_PERSISTENT_BANNER_ID}-footer {
        margin-top: ${theme.baseSpacingPx * 1.5}px;
        font-size: ${theme.font.sizePx.sm}px;
      }
      #${WIDGET_PERSISTENT_BANNER_ID}-footer img {
        width: auto;
        height: ${theme.font.sizePx.sm}px;
        margin-right: ${theme.baseSpacingPx}px;
      }
      #${WIDGET_PERSISTENT_BANNER_ID}.${WIDGET_ELEMENT_IS_OPEN_CLASS_NAME} {
        bottom: -${theme.baseSpacingPx / 2}px;
      }
      @media screen and (max-width: ${theme.breakpoints.mobile.px}px) {
        #${WIDGET_PERSISTENT_BANNER_ID} {
          bottom: calc(0px - 100svh);
          max-width: unset;
          height: auto;
        }
        #${WIDGET_PERSISTENT_BANNER_ID} h1 {
          padding: unset;
        }
        #${WIDGET_PERSISTENT_BANNER_ID}-content {
          align-items: flex-start;
          flex-direction: column;
        }
        #${WIDGET_PERSISTENT_BANNER_ID}-cta-button {
          width: 100%;
          margin-top: ${theme.baseSpacingPx * 2}px;
        }
      }
    </style>
    <div id='${WIDGET_PERSISTENT_BANNER_ID}-content'>
      <h1>Save up to 25% on business insurance, only for our customers.</h1>
      <button id='${WIDGET_PERSISTENT_BANNER_ID}-cta-button' class='${WIDGET_BUTTON_CLASS_NAME}'>
        Get a quote
      </button>
    </div>
    <div id='${WIDGET_PERSISTENT_BANNER_ID}-footer'>
      <img
        src='${AUTHENTIC_WEBSITE_LINK}/img/authentic/logo/logomark/logomark-onecolor-${colorIsDark(theme.palette.primary) ? "white" : "black"}.png'
        alt='${AUTHENTIC_BRAND_NAME} logomark'
      />
      <span>Powered by ${AUTHENTIC_BRAND_NAME}</span>
    </div>
    <button id='${WIDGET_PERSISTENT_BANNER_ID}-dismiss-button' class='${WIDGET_BUTTON_CLASS_NAME}'>
      ${getCloseIconHtml(theme, `color: ${theme.palette.primaryContrast};`)}
    </button>
  `;
  document.body.appendChild(persistentBanner);
  // Allow time for page to render before transitioning the persistent banner in.
  setTimeout(() => {
    persistentBanner.classList.add(WIDGET_ELEMENT_IS_OPEN_CLASS_NAME);
  }, MS_PER_SECOND);
  const persistentBannerDismissButton = document.getElementById(`${WIDGET_PERSISTENT_BANNER_ID}-dismiss-button`);
  if (persistentBannerDismissButton === null) {
    console.error("Authentic Widget error: Persistent banner dismiss button not rendered (injecting persistent banner).");
    return true;
  }
  persistentBannerDismissButton.addEventListener("click", dismissPersistentBanner);
  const persistentBannerCtaButton = document.getElementById(`${WIDGET_PERSISTENT_BANNER_ID}-cta-button`);
  if (persistentBannerCtaButton === null) {
    console.error("Authentic Widget error: Persistent banner CTA button not rendered (injecting persistent banner).");
    return true;
  }
  persistentBannerCtaButton.addEventListener("click", () => {
    // Inject the Insurance Portal iframe on CTA click so that analytics are not captured prior.
    injectIframe(insurancePortalUrl, "#" + WIDGET_MODAL_ID);
    // Allow time for the iframe to render before opening the modal.
    setTimeout(openModal, MS_PER_SECOND / 5);
    closePersistentBanner();
  });
  return false;
}

/**
 * Returns the HTML for a close (X) icon using the given {@linkcode Theme} (and optionally the given inline CSS) as a string.
 */
function getCloseIconHtml(theme: Theme, style?: string): string {
  return `
    <div style='display: flex; align-items: center; justify-content: center; position: relative; width: ${theme.font.sizePx.lg}px; height: ${theme.font.sizePx.lg}px; color: ${theme.palette.text}; ${style}'>
      <svg width='100%' viewBox='0 0 24 24' fill='currentColor'>
        <path d='M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z'></path>
      </svg>
    </div>
  `;
}
